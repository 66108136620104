import React from "react";

interface validationProps {
    value: string
    setError: (error: string) => void
    setVal:(error: string) =>  void
    errorMessage: string
    regExp: RegExp
}

export const validate = ({value, setError, setVal, errorMessage, regExp} :validationProps) => {
    if (value && !regExp.test(value)) {
        setError(errorMessage)
    } else {
        setError('')
    }
    setVal(value)
}