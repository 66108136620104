import axios from "axios";

const axiosInst = axios.create({
    baseURL: 'http://api.seti.ru.net',
    timeout: 5000,
    headers: {
        "Content-Type": 'application/json',
    }
})

export default axiosInst;