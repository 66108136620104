import {Route, Routes} from "react-router-dom";
import {useNotify} from "./ui/notifications/NotificationContext";
import {useEffect, useState} from "react";
import React from 'react';
import {Header} from "./components/header/Header";
import {LandingMain} from "./pages/introLanding/landingMain";
import {RegistrationPage} from "./pages/registration/registrationIndex";
import axiosInst from "./api/axios";
function App() {
    const {sendNotify} = useNotify()
    useEffect(() => {
        axiosInst.get('/test')
            .then(res => {
                sendNotify({
                    title: res.data.message,
                    // text: 'asdasdasd',
                    type: 'success'
                })
            })
            .catch(err => {
                sendNotify({
                    title: err.message,
                    // text: 'asdasdasd',
                    type: 'error'
                })
            })
        sendNotify({
            title: `Страница загружена ${window.location.pathname}`,
            // text: 'asdasdasd',
            type: 'alert'
        })
        sendNotify({
            title: `Здрасти, тепeрь на Ts`,
            // text: 'asdasdasd',
            type: 'warning'
        })
    }, [])

    return (
        <>
            <Header isLogin={false}/>

            <Routes>
                <Route path={'/'} element={<LandingMain/>}/>
                <Route path={'/registration'} element={<RegistrationPage/>}/>
            </Routes>
        </>
    );
}

export default App;
