import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/stylesConfig.scss';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {NotificationProvider} from "./ui/notifications/NotificationContext";
import {PopUpProvider} from "./ui/PopUpContext/PopUpContext";

const rootElement = document.getElementById('root')
if(!rootElement){
    throw new Error("Root element not found");
}

const root = ReactDOM.createRoot(rootElement);
root.render(
    // <Provider store={store}>
    <NotificationProvider>
        <PopUpProvider>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </PopUpProvider>
    </NotificationProvider>

    // </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

