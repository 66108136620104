import React, {createContext, ReactNode, useContext, useState} from "react";
import {NotificationTempalte} from "./notificationTempalte";

interface NotifyContext {
    sendNotify: (message: notify) => void;
    removeNotify: (id: number) => void;
}

const NotificationContext = createContext<NotifyContext | undefined>(undefined)
export const useNotify = ():NotifyContext => {
    const context = useContext(NotificationContext)
    if(!context){
        throw new Error("useNotify must be used within a NotificationProvider")
    }
    return context
}

type notifyType = 'warning' | 'error' | 'success' | 'alert'
type notify = {
    title: string
    type: notifyType
    text?: string
    duration?: number
    id?: number
}

interface NotificationProviderProps  {
    children: ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({children}) => {
    const [notifies, setNotifies] = useState<notify[]>([])

    const sendNotify = (notify: notify) => {
        setNotifies((prevNotifies) => [...prevNotifies, {...notify, id: Math.random() }])
    }

    const removeNotify = (id: number) => {
        setNotifies((prevNotifies) => prevNotifies.filter(notify => notify.id !== id ))
    }

    return(
        <NotificationContext.Provider value={{sendNotify, removeNotify}}>
            {children}
            <NotificationTempalte notifications={notifies} removeNotify={removeNotify} />
        </NotificationContext.Provider>
    )
}