

import './header.scss'
import {Button} from "../../ui/inputSystem/inputsSystem";
import {usePopUp} from "../../ui/PopUpContext/PopUpContext";
import {useNotify} from "../../ui/notifications/NotificationContext";
import React, {useState} from "react";
import logo from '../../static/logo-light.svg'


interface user{
    username: string

}
class User implements user{
    username;
    constructor(username: string) {
        this.username = username
    }
}

interface HeaderProps {
    isLogin: boolean
}
export const Header: React.FC<HeaderProps> = ({isLogin}) => {
    const [user, setUser] = useState<User[]>([new User('DiStudd')])
    return (
        <header>
                <nav>
                    <Button type={'nav'} link={'/'} style={'link'} text={'главная'}/>
                    <Button type={'nav'} link={'/ideas'} style={'link'} text={'проекты'}/>
                    <Button type={'nav'} link={'/peoples'} style={'link'} text={'поиск команды'}/>
                </nav>

                <a href={'/'} className="logo"><img src={logo} alt="sphere"/></a>

                <div className="user-controls">
                    <UserControls isLogged={false} messages_count={6} username={user[0].username}/>
                </div>
        </header>
    )
}

interface UserControlsProps {
    messages_count: number
    username: string
    isLogged: boolean
}
const UserControls: React.FC<UserControlsProps> = ({isLogged = false, messages_count, username}) => {
    const {openPopUp} = usePopUp()

    if (isLogged) {
        return (
            <>
                <p>{username}</p>
                <div className="user-avatar">
                    <img
                        src="https://avatars.mds.yandex.net/i?id=1a005277818e2267b56991cd17e8d072_l-8173407-images-thumbs&n=13"
                        alt=""/>
                </div>
                {messages_count >= 1 && <p className="messages">{messages_count >= 10 ? '9+' : messages_count}</p>}
            </>
        )
    } else {
        return (
            <>

                <Button type={'nav'} style={'squared'} link={'/auth'} text={'вход'}/>

                <Button link={'/registration'} style={'squared'} type={'nav'} text={'регистрация'}/>
            </>
        )
    }
}
