import React, {useEffect} from "react";
import './notification.scss'

interface NotificationTempalteProps {
    notifications: any[]
    removeNotify: (id: number) => void
}
export const NotificationTempalte = ({notifications, removeNotify}: NotificationTempalteProps) => {
    useEffect(() => {
        const timers = notifications.map((toast) =>
            setTimeout(() => removeNotify(toast.id), toast.duration !== undefined ? toast.duration : 2200)
        );
        return () => timers.forEach((timer) => clearTimeout(timer));
    }, [notifications, removeNotify]);

    return (
        <div className={'notificationsContainer'}>
            {notifications.map((notify) => (
                <div key={notify.id} className={'notify ' + notify.type}>
                    <h2 className="title">{notify.title}</h2>
                    <p className="text">{notify.text}</p>
                </div>
            ))}
        </div>
    )
}
