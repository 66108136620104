import {Link} from "react-router-dom";
import './inputsStyles.scss'
import React, {useEffect, useRef, useState} from "react";

type ButtonType = `nav` | 'input' | ''
interface ButtonProps {
    text: string
    id?: string
    link?: string
    onClick?: (e: any) => void
    type?: ButtonType
    style?: string
}
export const Button: React.FC<ButtonProps> = ({text = 'button text', id , link = '#', onClick, type, style = 'squared'}) => {
    switch (type) {
        case 'nav':
            return (
                <Link id={id} onClick={onClick} className={`btn default ${style}`} to={link}>
                    {text}
                </Link>
            )
        case 'input':
            return (
                <button id={id} onClick={onClick} className={`btn default ${style}`}>
                    {text}
                </button>
            )
        default:
            return (
                <a id={id} onClick={onClick} className={`btn default ${style}`} href={link}>
                    {text}
                </a>
            )
    }
}

type InputType = 'multi' | ''
interface InputProps {
    type?: InputType
    input_type?: string
    placeholder?: string
    prefix?: string
    label: string
    message?: string
    isError?: boolean
    maxSize?: number
    value?: string
    onChange?: (e: any) => void
}
export const Input: React.FC<InputProps> = ({
                          type,
                          input_type,
                          placeholder,
                          prefix,
                          label,
                          message,
                          isError = true,
                          maxSize,
                          value ='',
                          onChange
                      }) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [inputVal, setInputVal] = useState('')

    const multiRef1 = useRef<HTMLInputElement | null>(null)
    const multiRef2 = useRef<HTMLInputElement | null>(null)
    const multiRef3 = useRef<HTMLInputElement | null>(null)


    useEffect(() => {
        setInputVal(value)
    }, [value])


    switch (type) {
        case 'multi' :
            return (
                <div className={'input_multi_type'}>
                    <div className="row">
                        <label className={'input_label'} htmlFor={placeholder}>{label}</label>
                        <p className={'input_validation ' + isError}>{message}</p>
                    </div>
                    <div onClick={() => {
                        // inputRef.current.focus()
                    }} className={'body row'}>
                        <input pattern={'[0-9]{3}'} maxLength={3} onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                           const target = e.target as HTMLInputElement
                            if (target.value.length >= 3 && e.keyCode !== 8) multiRef2.current?.focus()
                        }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length >= 3) multiRef2.current?.focus()
                        }
                        } id={placeholder} ref={multiRef1} placeholder={placeholder} className={'hidden_input'}
                               type={input_type}/>
                        <p className="multi-input-devider">-</p>
                        <input pattern={'[0-9]{3}'} maxLength={3}
                               onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                   const target = e.target as HTMLInputElement
                                   if (target.value.length === 0 && e.keyCode === 8) multiRef1.current?.focus()
                               }}
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                   if (e.target.value.length >= 3) multiRef3.current?.focus()
                                   if (e.target.value.length === 0) multiRef1.current?.focus()
                               }}
                               id={placeholder} ref={multiRef2} placeholder={placeholder} className={'hidden_input'}
                               type={input_type}/>
                        <p className="multi-input-devider">-</p>
                        <input pattern={'[0-9]{3}'} maxLength={3}
                               onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                   const target = e.target as HTMLInputElement
                                   if (target.value.length === 0 && e.keyCode === 8) multiRef2.current?.focus()
                               }}
                               onChange={(e) => {
                                   if (e.target.value.length === 0) multiRef2.current?.focus()
                               }} id={placeholder} ref={multiRef3} placeholder={placeholder} className={'hidden_input'}
                               type={input_type}/>
                    </div>
                </div>
            )
        default:
            return (
                <div className={'input_default_type '}>

                    <div className="row">
                        <label className={'input_label'} htmlFor={placeholder}>{label}</label>
                        <p className={'input_validation ' + isError}>{message}</p>
                    </div>
                    <div onClick={() => {
                        inputRef.current?.focus()
                    }} className={'body ' + (message ? 'message ' : '') + isError}>
                        <p className="input_prefix">{prefix}</p>
                        <input maxLength={maxSize} size={inputVal.length} onChange={(e) => {
                            setInputVal(e.target.value);
                            onChange && onChange(e)
                        }} id={placeholder} ref={inputRef} placeholder={placeholder} className={'hidden_input'}
                               type={input_type}/>
                        {/*<p className="input_placeholder">{placeholder}</p>*/}

                    </div>
                </div>
            )
    }
}

