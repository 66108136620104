import './PopUpStyles.scss'
import React, {ReactNode} from "react";

interface PopUpTemplateProps {
    title: string
    hasExitBtn?: boolean
    closeFunc?: () => void
    children?: ReactNode
    hidden?: boolean
}

export const PopUpTemplate = ({title, hasExitBtn = true, closeFunc, children, hidden}: PopUpTemplateProps) => {
    return (
        <div className={'modal-bg'} hidden={hidden} onClick={closeFunc}>
            <div className={'PopUp'} onClick={(e) => e.stopPropagation()}>
                {/*<button onClick={closeFunc}>X</button>*/}
                <p className="title">{title}</p>
                {children}
            </div>
        </div>

    )
}
