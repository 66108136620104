import {Button, Input} from "../../ui/inputSystem/inputsSystem";
import './styles/formStyles.scss'
import {validate} from "./validation";
import React, {useState} from "react";
export const RegistrationPage = () => {
    const [emailError, setEmailError] = useState('')
    const [email, setEmail] = useState('')

    const [loginError, setLoginError] = useState('')
    const [login, setLogin] = useState('')
    return (
        <form className={'multistep-form form'}>
                <section className="form-part">
                    <h1 className="form-title">Регистрация</h1>
                    <Input onChange={(e) => {validate({
                        value: e.target.value,
                        setError: setLoginError,
                        setVal: setLogin,
                        errorMessage: 'Неверный формат (A-z, 0-9)',
                        regExp: /^[a-zA-Z0-9]+$/
                    })}} input_type={'text'} placeholder={'логин'} label={'логин'} message={loginError} maxSize={40} prefix={'@'}/>
                    <Input onChange={(e) => {validate({
                        value: e.target.value,
                        setError: setEmailError,
                        setVal: setEmail,
                        errorMessage: 'Введите корректный email!',
                        regExp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    })}} message={emailError} input_type={'email'} placeholder={'exapmle@seti.ru.net'} maxSize={45} label={'email'}/>

                    <div className="row">
                        <Input input_type={'name'} maxSize={20} label={'Имя'}/>
                        <Input input_type={'name'} maxSize={20} label={'Фамилия'}/>
                    </div>

                    <Input input_type={'new password'} maxSize={25} label={'пароль'}/>
                    <Input input_type={'password'} maxSize={25} label={'повторите пароль'}/>
                    <div className="row">
                        <Input type={'multi'} placeholder={'000'} maxSize={25} label={'код приглашения'}/>
                        <a href={'#'} title={'Для участия в бета тестировании обратитесь по адресу sfdsf@dsfsdf.ds'} className="note">Как получить код?</a>
                    </div>


                    {/*<Button text={'Далее'}/>*/}


                    <div className="row">
                        <Button type={'nav'} style={'link width100'} link={'/auth'} text={'Есть аккаунт'}/>
                        <Button type={"input"} style={'squared width100'} text={'Далее'}/>
                    </div>
                </section>
        </form>
    )
}
