import React, {createContext, ReactNode, useContext, useState} from "react";
import {PopUpTemplate} from "./PopUpTemplate";

interface PopUpContextType {
    openPopUp: (content: ReactNode) => void
    closePopUp: () => void
}


const PopUpContext = createContext<PopUpContextType | undefined>(undefined)

export const usePopUp = (): PopUpContextType => {
    const context = useContext(PopUpContext);
    if (!context) {
        throw new Error("usePopUp must be used within a PopUpProvider");
    }
    return context;
}

interface PopUpProviderProps {
    children: ReactNode
}

export const PopUpProvider: React.FC<PopUpProviderProps> = ({children}) => {
    const [popUpHidden, setPopUpHidden] = useState(true)
    const [content, setContent] = useState<ReactNode>(<></>)
    const openPopUp = (content: ReactNode) =>{
        setPopUpHidden(false)
        setContent(content)
    }
    const closePopUp = () =>{
        setPopUpHidden(true)
    }

    return (
        <PopUpContext.Provider value={{openPopUp, closePopUp}}>
            {children}
            <PopUpTemplate title={'popup'} hidden = {popUpHidden} closeFunc={closePopUp} children={content}/>
        </PopUpContext.Provider>
    )
}

